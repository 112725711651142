<script>
import appConfig from "@/app.config";
import { required ,maxLength} from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect'
// import SideButtons from '../../../../../../components/side-buttons.vue';
import Swal from "sweetalert2";
import { getDropDownElementsByTypeApi } from "@/api/common";

export default {

    page: {
        title: "Magasin",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: {Multiselect/* ,SideButtons  */},
    props:{
     magasin: {
      type: Array,
      default: null,
    }
    },
  data() {
    return {
      title: "Nouvelle Magasin",
      warehouseForm: {
        name: "",
        lifebase_id:"",
        bloc_id:"",
        type:"",
        description: "",
      },
      lastSelected : null,
      bases:[],
      blocs:[],
      types:[],
      selectedBdv:[],
      selectedBloc:[],
      selectedType:[],
      userStructuresTypes: [],

      submitted     : false,
      submitform    : false,
      submit        : false,
      typesubmit    : false,
      have          : false,
      send          : false,
      disabled      : true,
      add           : false,
      edit          : false,
      destroy       : false,
      resetForm     : false,
      disabledAdd   : false,
      disabledEdit  : true,
      disabledDelete: true,
      disabledCancel: true,
      
    };
  },
  validations: {
    warehouseForm: {
      name: { required, maxLength: maxLength(64) },
      lifebase_id: { required},
      bloc_id: { required},
      type: { required},
    },
  },

  watch:{
    magasin(){
      this.disabled       = true;
      this.disabledAdd    = false;
      this.disabledDelete = true;
      this.disabledEdit   = true;
      if(this.magasin[0]){
        this.getBlocs(this.magasin[0].lifebase_id);
        this.warehouseForm.id          = this.magasin[0].id;
        this.warehouseForm.uuid        = this.magasin[0].uuid;
        this.warehouseForm.name        = this.magasin[0].name;
        this.warehouseForm.lifebase_id = this.magasin[0].lifebase_id;
        this.warehouseForm.bloc_id     = this.magasin[0].bloc_id;
        this.warehouseForm.type        = this.magasin[0].type;
        this.warehouseForm.description = this.magasin[0].description;

        this.selectedBdv = this.bases.filter(x => x.id == this.warehouseForm.lifebase_id)
        this.selectedBloc = this.blocs.filter(x => x.id == this.warehouseForm.bloc_id)
        this.selectedType = this.types.filter(x => x.id == this.warehouseForm.type)
        this.lastSelected = this.magasin[0];
        // this.disabledAdd    = false;
        this.disabledDelete = false;
        this.disabledEdit   = false;
        this.disabledCancel = true;
      }else{
        this.warehouseForm= {
          name: "",
          lifebase_id:"",
          bloc_id:"",
          type:"",
          description: "",
        }
        this.have = false;
        this.lastSelected = null;
      }
    }  ,
    add:{
      handler(val){
        this.resetForm=false;
        if(val && this.$can('create_warehouse')){
          this.disabled = true;
          this.resetData();
        }
        else if(val && !this.$can('create_warehouse')){ this.resetForm=true; Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')}
      }
    },
    edit:{
      handler(val){
        this.resetForm=false;
        if(val  && this.$can('edit_warehouse')){
          this.disabled = true;
        }
        else if(val && !this.$can('edit_warehouse')){ this.resetForm=true; Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')}
      }
    },
    destroy:{
      handler(val){
        this.resetForm=false;
        if(val && this.$can('delete_warehouse')) {this.deleteWarehouse();}
        else if(val && !this.$can('delete_warehouse')){ this.resetForm=true; Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')}
      }
    },
    resetForm:{
        handler(val){
            if(val) {
                console.log('reset');
                this.resetData();
                this.disabled=false;
            }
        }
    },
  },
  mounted(){
    this.getLifeBases();
    this.getListWarehouseTypes()
},

 methods:{
   clearForm(){
     this.warehouseForm= {
          name: "",
          lifebase_id:"",
          bloc_id:"",
          type:"",
          description: "",
        }
      this.selectedBdv = [];
      this.selectedBloc = [];
      this.selectedType = [];
   },
    addBtn(){
      this.add            = false;
      this.disabled       = false;
      this.disabledAdd    = true;
      this.disabledEdit   = true;
      this.disabledCancel = true;
      this.clearForm();
    },
    editBtn(){
      this.edit           = false;
      this.disabledAdd    = true;
      this.disabledEdit   = true;
      this.disabled       = false;
      this.disabledCancel = true;
    },
    cancelBtn(){
      // this.resetForm = true;
      this.clearForm();
      this.disabled       = true;
      this.disabledAdd    = false;
      this.disabledEdit   = true;
      this.disabledCancel = true;
      if(this.lastSelected != null){
        this.disabledEdit   = false;
        this.addSelectedRow();
      }
    },

    addSelectedRow(){
      this.warehouseForm= {
          name: "",
          lifebase_id:"",
          bloc_id:"",
          type:"",
          description: "",
        }
        // this.have = false;
      this.resetData();
      this.disabled = true;
      this.add      = false;
      this.edit     = false;
      if(this.lastSelected != null){
        this.getBlocs(this.lastSelected.lifebase_id);
        this.warehouseForm.id          = this.lastSelected.id;
        this.warehouseForm.uuid        = this.lastSelected.uuid;
        this.warehouseForm.name        = this.lastSelected.name;
        this.warehouseForm.lifebase_id = this.lastSelected.lifebase_id;
        this.warehouseForm.bloc_id     = this.lastSelected.bloc_id;
        this.warehouseForm.type        = this.lastSelected.type;
        this.warehouseForm.description = this.lastSelected.description;

        this.selectedBdv = this.bases.filter(x => x.id == this.warehouseForm.lifebase_id)
        this.selectedBloc = this.blocs.filter(x => x.id == this.warehouseForm.bloc_id)
        this.selectedType = this.types.filter(x => x.id == this.warehouseForm.type)
      }
    },
    getLifeBases(){
      var _this = this
      this.$http.get('/base/lifebases/index')
      .then(response => {
          _this.bases = response.data;
      })
      .catch(error => console.log(error))
    },
    getBlocs(id) {
      var _this = this;
      
      this.$http
        .post("/infrastructure/blocs/list",{'life_base_id':id})
        .then((res) => {
          
          _this.blocs = res.data.original.list;
          if(this.magasin[0]){
            this.selectedBloc = this.blocs.filter(x => x.id == this.warehouseForm.bloc_id)
          }
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
    },
    getListWarehouseTypes(){
      getDropDownElementsByTypeApi('warehouse_type')
      .then(res => {
        this.types = res.data.original.list
      })
      .catch(error => console.log(error))
    },
    getLifeBaseID(lifebase){
      this.warehouseForm.lifebase_id = lifebase.id;
      this.getBlocs(lifebase.id);
    },
    getBlocID(bloc){
      this.warehouseForm.bloc_id = bloc.id
    },
    getTypeID(type){
      this.warehouseForm.type = type.id
    },
    resetData(){
        this.warehouseForm= {
          name: "",
          lifebase_id:"",
          bloc_id:"",
          type:"",
          description: "",
        }
        this.have = false;
        this.send=false;

        this.selectedBdv=[];
        this.selectedBloc=[];
        this.selectedType=[];
    },
     formSubmit() {
       
      this.submitted = true;
      var valid = true;
      // var validFormData = this._validateContractAmnts();
      var router = this.$router;

      if(valid){
      this.$http
        .post("/system/stock/warehouses/store", this.warehouseForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              Swal.fire("Félicitations!", res.data.original.msg, "success");
              this.$emit('refresh',true);
              this.resetData();
              this.have = false;
              // this.disabled = false
              this.cancelBtn();
              this.send=false;
              this.edit=false;
              
              break;

            case 500:
              Swal.fire("Avertissement!", res.data.original.msg, "warning");
              break;
          }
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
      }

    },
    deleteWarehouse() {
      var roleName = this.warehouseForm.name;
      var uomUid = this.warehouseForm.uuid;
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer le magasin: " + roleName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/system/stock/warehouses/delete/" + uomUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                    this.$toast.success(res.data.original.msg);
                  this.$emit('refresh',true);
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
  }
    
}
</script>
<style>
  #StickyEF {
    position: fixed;
    right: 0;
    top: 50%;
    width: 8em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
  .rounded-pillEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 50% !important;
  }
  .btn-addEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #044A72 ;
  }
  .btn-duplicateEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #097EA9 ;
  }
  .btn-editEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #FFB236 ;
  }
  .btn-deleteEF #dropdown-dropleft__BV_toggle_{
     border-radius: 50% !important;
  }
  .btn-deleteEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #D61337;
  }
  .btn-uploadEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #2A5769 ;
  }
  .btn-downloadEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #21BF92;
  }
  .btn-cancelEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }
  .form-check-input{
    font-size: 24px;
  }
</style>
<template>
    <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-lg-12"><!-- 
        <SideButtons
          :select="have" :save="send" :ODS="false" @add="add = $event" 
            @saveToPending="saveToPending = $event" @edit=" edit = $event" 
           @resetEmit="resetForm = $event" @delete=" destroy = $event" /> -->
          <ul id="StickyEF">
            <li>
              <button type="button" @click="addBtn" :disabled="disabledAdd  && !$can('create_warehouse')" class="btn btn-addEF rounded-pillEF float-right mr-1 mt-1">
              <i class="fas fa-plus text-white"></i>
              </button>
            </li>
            <li>
              <button type="button" @click="editBtn" :disabled="disabledEdit  && !$can('edit_warehouse')" class="btn btn-editEF rounded-pillEF float-right mr-1 mt-1">
                <i class="fas fa-pencil-alt text-white" style=""></i>
              </button>
            </li>
            <li>
              <button type="button" :disabled="disabledEdit  && !$can('delete_warehouse')" @click="deleteWarehouse" class="btn btn-deleteEF rounded-pillEF float-right mr-1 mt-1">
                <i class="fas fa-trash-alt text-white" style=""></i>
              </button>
            </li>
          </ul>
        <div class="p-3">
          <div class="">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <!-- <b-alert show variant="info">
                <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
                obligatoires sont marqués avec (*)
              </b-alert> -->
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="formrow-email-input">Désignation *</label>
                    <input
                      id="projectname"
                      v-model="warehouseForm.name"
                      type="text"
                      class="form-control"
                      :disabled="disabled"
                      :class="{
                        'is-invalid': submitted && $v.warehouseForm.name.$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="submitted && $v.warehouseForm.name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.warehouseForm.name.required"
                        >Le Champ est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="validationCustom02">Base de vie</label>
                    <multiselect v-model="selectedBdv" :select-label="''" @select="getLifeBaseID" :disabled="disabled" :class="{'disabledMS':disabled , 'is-invalid': submitted && $v.warehouseForm.lifebase_id.$error,}" :searchable="true" track-by="id" label="name" :options="bases" placeholder="Selectionner Une Base de vie" :allow-empty="false">
                      <template slot="singleUom" slot-scope="{ base }">{{ base.name }}</template>
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="validationCustom02">Bloc</label>
                    <multiselect v-model="selectedBloc" :select-label="''" @select="getBlocID" :disabled="disabled" :class="{'disabledMS':disabled,'is-invalid': submitted && $v.warehouseForm.bloc_id.$error,}" :searchable="true" track-by="id" label="name" :options="blocs" placeholder="Selectionner un Bloc" :allow-empty="false">
                      <template slot="singleUom" slot-scope="{ bloc }">{{ bloc.name }}</template>
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="validationCustom02">Type</label>
                    <multiselect v-model="selectedType" :select-label="''" @select="getTypeID" :disabled="disabled" :class="{'disabledMS':disabled,'is-invalid': submitted && $v.warehouseForm.type.$error,}" :searchable="true" track-by="id" label="designation" :options="types" placeholder="Selectionner un Type" :allow-empty="false">
                      <template slot="singleUom" slot-scope="{ type }">{{ type.designation }}</template>
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="formrow-email-input">Description</label>
                    <input
                      id="projectname"
                      v-model="warehouseForm.description"
                      type="text"
                      class="form-control"
                      :disabled="disabled"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                
                <div class="col text-right mr-2 mb-3" v-if="!disabled && ($can('create_warehouse') && $can('edit_warehouse'))">
                  <button type="button"  @click="cancelBtn" class="btn btn-light btn-label float-right ml-2">
                    <i class="fas fa-times-circle text-danger label-icon"></i> Annuler
                  </button>
                  <b-button type="submit" :disabled="$v.warehouseForm.$invalid"  variant="primary" class="btn-label">
                      <i class="far fa-save text-white label-icon"></i> Enregitrer
                  </b-button>
                  <!-- <b-button :disabled="this.$v.$invalid" @click="onComplete" variant="info" class="btn-label">
                      <i class="far fa-paper-plane text-white label-icon"></i> Enregitrer pour COnfirmation
                  </b-button> -->
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- </Layout> -->
</template>
